// @import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@use '@angular/material' as mat;

@import '../node_modules/@angular/material/theming';
@import "~@ng-select/ng-select/themes/material.theme.css";
// mat-core required just once in project
@include mat.core();

// import our custom theme
@import './theme.scss';

//@import '~ngx-daterangepicker-material/daterangepicker-theme.scss';

// DateRangePicker theme
//@include date-range-picker-theme($theme);

@include mat.all-component-themes($custom-light-theme);

@import './app/core/directives/mat-table-responsive.directive';

html,
body {
  font-family: 'Muli';
  min-height: 99vh;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

table {
  width: 100%;
}

h1 h2 {
  font-family: 'Basic Sans', sans-serif;
}

.mat-input-underline {
  display: none !important;
}

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 0px;
}


.mat-list-base .mat-list-item {
  height: 28.75px;
  width: 85.13px;
  color: #BDBDBD;
  font-family: Roboto;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
}

.mat-list-item-content {
  text-align: center;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
}

.controls-container {
  height: 100%;
  width: 90%;
  padding-bottom: 10px;
}

.controls-container>* {
  width: 100%;
}

tr.mat-header-row {
  height: 36px !important;
}

tr.mat-row {
  height: 36px !important;
}

.list-container {
  width: 80%;
  padding: 1%;
}

.list-container>* {
  width: 100%;
}

.button-row button {
  margin: 5px;
}

.dialog-header {
  text-align: center;
  font-size: 2em;
}

.learner-badge {
  text-align: right;
  width: 50%;
  font-size: 1rem;
}

#events {
  width: 100%;
}

.internalMatGrid {
  min-width: 100%;
}

#events-selector {
  width: 100%;
}

.event-date {
  color: lightblue;
}

.event-type {
  color: royalblue;
}

.full-width-dialog .mat-dialog-container {
  max-width: 100vw !important;
}

.mat-card {
  padding: 5px
}

.mat-card-title {
  text-align: center;
}

.circle-padding {
  padding: 10px;
}


.info-timeline ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
}

.info-timeline ul li .timeline-circle {
  position: relative;

  border-radius: 100%;
  width: 3vw;
  line-height: 3vw;
  text-align: center;
  margin-top: 50px;
  // background-color: #fff;
  z-index: 2;
  display: inline-block;
}

.info-timeline ul li:not(:first-of-type) .timeline-circle::before {
  position: absolute;
  width: 0;
  height: 50px;
  display: block;
  content: '';
  left: 50%;
  z-index: 1;
  top: -54px;
  margin-left: -1px;
}

.mat-toolbar.mat-primary {
  z-index: 3 !important;
}

// .info-timeline ul li:not(:first-of-type) .milestone-complete::before {
//   border: 1px solid green;
// }

// .info-timeline ul li:not(:first-of-type) .milestone-pending::before {
//   border: 1px solid #999;
// }

// .info-timeline ul li:not(:first-of-type) .milestone-overdue::before {
//   border: 1px solid red;
// }

// .info-timeline ul li .milestone-complete {
//   border: 2px solid green;
//   color: green;
// }

// .info-timeline ul li .milestone-pending {
//   border: 2px solid #999;
//   color: #999;
// }

// .info-timeline ul li .milestone-overdue {
//   border: 2px solid red;
//   color: red;
// }

mat-sidenav {
  text-align: center;
}

mat-sidenav-content {
  overflow: hidden !important;
}


h2.dashboard-titles {
  font-family: 'Basic Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

h2.dashboard_headers {
  font-size: 24px;
  font-weight: 300;
}

h3.dashboard_headers {
  font-size: 20px;
  font-weight: 300;
}

h4.report_headers {
  font-size: calc(13px + 0.35vw);
  font-weight: 300;
  margin: 10px 0 10px 0;
}

.menu-icons {
  // width: 2rem !important;
  // height: 2rem !important;
  font-size: 2rem !important;
  margin-bottom: 50%;
}

.material-icons {
  font-size: 30px;
  color: #4F4F4F;
}

.material-icons-selected {
  color: white;
}

// .material-icons:hover {
//     color: lightblue;
//     cursor: pointer;
// }

div.menu-item {
  // height: 28.75px;
  width: 100%;
  color: #BDBDBD;
  font-family: Roboto;
  font-size: calc(10px + 0.4vw);
  line-height: calc(10px + 0.4vw);
  text-align: center;
  padding-bottom: 8px;
}

div.card-info {
  color: #334;
  font-family: Roboto;
  font-size: 1em;
  line-height: 1.2em;
  font-weight: normal;
}

span.card-info-text {
  color: #334;
  font-family: Roboto;
  font-size: 1em;
  line-height: 1.2em;
  font-weight: lighter;
}

/* Responsive Design */
// @media only screen and (max-width: 1250px) {
//   span.card-info-text {
//     font-size: 12px;
//     line-height: 13px;
//   }

//   span.card-title {
//     font-size: 15px;
//     line-height: 14px;
//   }

//   div.card-info {
//     font-size: 13px;
//     line-height: 14px;
//   }
// }

span.card-title {
  height: 23px;
  width: 250.71px;
  color: #4F4F4F;
  font-family: Roboto;
  font-size: 1vw;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
}


.chartContainer canvas {
  max-height: 250px;
  width: auto;
}

.chartContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// :host ::ng-deep .Frill_Trigger_Floating--bottom-right, :host ::ng-deep .Frill_Trigger  {
//   background-color: grey !important;
// }

:host ::ng-deep mat-icon {
  vertical-align: middle;
}

.mat-standard-chip {
  min-width: 100px;
  justify-content: center;
}


body .mat-standard-chip {
  // height: 63px !important;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding: auto auto;
}

.mat-chip-list {
  margin-left: 20px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0px !important;
}

.mat-form-field-appearance-fill {
  .mat-form-field-infix {
    padding: 0;
  }
}

.mat-card {
  text-align: center;
  border-radius: 10px solid;
  margin: auto auto;
}

.mat-tab-link {
  color: rgb(39, 39, 39);
  font-family: Roboto !important;
  font-size: calc(12px + 0.4vw) !important;
  line-height: 21px !important;
  opacity: 1 !important;
  min-width: 80px !important;
  margin-right: 3% !important;
  font-weight: 400;
}

/* Responsive Design */
//Tablets:


.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #000;
  height: 2px;
}

.mat-divider {
  border-top-width: 2px;
}

/***************
 * Report Template*
 ***************/
ng-sidebar-container.sidebar-container {
  height: 100vh;
  overflow-y: hidden !important;
}

.ng-sidebar--opened {
  z-index: 101 !important;
}

.option-select {
  font-size: 13px;
}

.group-select {
  font-size: 13px;
}

aside {
  overflow-x: hidden !important;
  background-color: #483B8D;
}

.filter-button {
  margin: 0 !important;
  padding: 0 3px 0 3px;
  width: auto !important;
  color: white;
}

.filter-close-button {
  margin: 0 !important;
  float: right;
  padding-right: 10px;
  color: white;
}

.create-button {
  background: #1F66F1;
  border-radius: 5px;
  color: white;
  transition: 0.1s;
}

.create-button:hover {
  background: #0246ce;
}

.share-button {
  background: #1F66F1;
  border-radius: 5px;
  color: white;
  transition: 0.1s;
}

.share-button:hover {
  background: #0246ce;
}

.submit-button {
  background: #1F66F1;
  border-radius: 5px;
  color: white;
  transition: 0.1s;
}

.submit-button:hover {
  background: #0246ce;
}

.save-as-button {
  background: #F9F6F2;
  border: 1px solid #1F66F1 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  color: #1F66F1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.1s;
}

.save-as-button:hover {
  background: #0246ce;
}

.submit-iqa-button {
  background: #F9F6F2;
  border: 1px solid #1F66F1 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1F66F1;
  transition: 0.1s;
}

.submit-iqa-button:hover {
  font-weight: bold;
}

.export-as-button {
  background: #F9F6F2;
  border: 1px solid #1F66F1 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1F66F1;
  transition: 0.1s;
}

.export-as-button:hover {
  font-weight: bold;
}

.export-as-button-arrow {
  border-left: 1px solid #1F66F1 !important;
  padding: 10px 0 10px 10px;
  margin-left: 10px;
  transition: 0.1s;
}

.filter-blue {
  filter: invert(25%) sepia(97%) saturate(3274%) hue-rotate(218deg) brightness(103%) contrast(89%);
  transition: 0.1s;
}

.mat-tab-label {
  width: 50%;
}

div.reportContainer {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-top: 25px;
  max-height: 1500px;
  overflow-x: hidden;
}

@media only screen and (max-width: 1000px) {
  div.reportContainer {
    padding: 15px 20px 20px 20px;
  }
}


.reportTable {
  clear: both;
}

div.reportOptionsContainer {
  display: inline-block;
  height: 40px;
  width: 100%;
  vertical-align: bottom;
}

div.reportButtonsContainer {
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
}

.reportTitle {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.5px;
  font-family: 'Basic Sans', sans-serif;
}

.mat-drawer-container {
  background-color: #F9F6F2;
}

.mat-button-toggle-label-content {
  line-height: 36px !important;
}

button.reportOptionButton {
  margin-right: 20px;
}

button.filterOptionButton {
  color: rgba(0, 0, 0, 0.87);
  background: white;
  float: left;
  flex: 1
}

.toggle-report-buttons {
  margin-bottom: 10px;
  float: right;
}

.reportTable {
  margin-top: 15px;
  // height: 100vh;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  text-align: left;
}

.mat-sort-header-container {
  display: flex;
  text-align: left;
  padding-left: 5px;
}

.mat-sort-header-content {
  text-align: left !important;
}

.filter-bar {
  padding: 0px 0px 0px 20px;
  border-left: 1px black;
  height: -webkit-fill-available;
  // overflow-y: hidden;
  font-size: 1.5vh;
}

div.filter-bar-inner {
  // height: 80vh;
  height: 90vh;
  overflow-y: auto;
}

@media only screen and (min-height: 850px) and (max-height: 900px) {
  div.filter-bar-inner {
    // height: 75vh;
  }

  .reportTable {
    max-height: 70vh;
  }

}


@media only screen and (min-height: 700px) and (max-height: 850px) {
  div.filter-bar-inner {
    // height: 70vh;
  }

  .reportTable {
    max-height: 70vh;
  }
}

@media only screen and (min-height: 500px) and (max-height: 700px) {
  div.filter-bar-inner {
    //  height: 60vh;
  }


  .reportTable {
    max-height: 65vh;
  }
}

// html {
//   overflow: hidden; // reason for double scroll on reports. but the close button would be unclickable if we had this.
// }

@media only screen and (max-height: 500px) {
  div.filter-bar-inner {
    //   height: 50vh;
  }


  .reportTable {
    max-height: 50vh;
  }
}

h4 {
  font-weight: 500;
  size: 14px;
  line-height: 16.5px;
}

.date-range-picker {
  min-height: 30px;
  border: none !important;
  border-bottom: none !important;
  cursor: pointer;
  background-color: #e0e0e0;
  font-family: Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 450;
  font-size: 1.4vh;
}

.report-filter-toggle {
  margin: auto;
  font-size: 2em;
  color: #fff;
  margin-right: 20px;
  float: right;
  height: auto !important;
  width: auto !important;
}

table.table-condensed {
  font-size: 1.4vh !important;
}

.star {
  vertical-align: sub;
  margin-left: 15px;
  cursor: pointer;
}

.star:hover {
  filter: brightness(0.85);
}

.star-favourite {
  @extend .star;
  color: gold;
}

.filter {
  margin: 20% 10px 0 0;
  cursor: pointer;
}

.filter:hover {
  filter: brightness(0.85);
}

.reportToggle {
  padding-right: 10px;
}


/***************
 * IQA Form Styling*
 ***************/

.iqa-new-record {
  width: 100%;
}

.iqa-new-record-50 {
  width: 50%;
}

.iqa-new-record-45 {
  width: 40%;
  padding-right: 5%;
}

iqa-new-record-80 {
  width: 80%;
}

.new-record-form {
  display: block;
  margin: auto;
  width: 100%;
  overflow-x: hidden !important;
}

app-iqa-record-form {
  width: 95%;
}

:host ::ng-deep mat-grid-tile.mat-grid-tile {
  margin-top: 0 !important;
  padding-top: 0 !important;
}


.iqaformDivider {
  margin-bottom: 10px;
  min-width: 650px;
}


/***************
 * General Styling*
 ***************/

.body-text {
  color: #334;
  font-family: Roboto;
  font-size: calc(11px + 0.25vw);
  line-height: calc(11px + 0.35vw);
}

.body-text-title {
  font-family: Roboto;
  font-size: calc(13px + 0.25vw);
  line-height: calc(13px + 0.35vw);
}

@mixin comp-titles {
  min-height: 23px;
  color: #4F4F4F;
  font-family: 'Basic Sans', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  padding-left: 20px;
}

.progressTitle {
  @include comp-titles;
}

.IQAtitle {
  @include comp-titles;
  padding: 10px 0px 10px 0px;
}

.dashboardTitle {
  @include comp-titles;
  margin-bottom: 10px !important;
  padding-left: 0px;
}

.eventsTitle {
  @include comp-titles;
  padding-left: 0px;
}

.reportsTitle {
  @include comp-titles;
}

.eventsTitle-milestones {
  @include comp-titles;
}

@mixin outcome-stamps {
  height: calc(12px + 0.75em);
  font-family: Arial;
  font-size: calc(10px + 0.3em);
  font-weight: 760;
  text-align: center;
  text-transform: uppercase;
  padding: 3px 12px 5px 12px;
  border-radius: 2px;
  margin-left: 6px;
  margin-right: 2px;
}

.outcome-stamp-pass {
  @include outcome-stamps;
  color: #108C27;
  background-color: #ddffe4;
}

.outcome-stamp-fail {
  @include outcome-stamps;
  color: #D9162C;
  background-color: #ffccd4;
}

.outcome-stamp-waiting {
  @include outcome-stamps;
  color: #d95d16;
  background-color: #ffdccc;
}

.outcome-stamp-draft {
  @include outcome-stamps;
  color: black;
  background-color: #e4e4e4;
}

.outcome-stamp-skipped {
  @include outcome-stamps;
  color: black;
  background-color: #cff0fa;
}

.outcome-stamp-referred {
  @include outcome-stamps;
  color: black;
  background-color: #e7daf8;
}

.outcome-stamp-provisional {
  @include outcome-stamps;
  color: black;
  background-color: #f8f8ce;
}

.outcome-stamp-unknown {
  @include outcome-stamps;
  color: black;
  background-color: #e4e4e4;
}

.details-icon {
  float: right;
  margin-right: 2%;
  height: auto !important;
  font-size: 2rem !important;
  color: gray;
  cursor: pointer;
  width: auto !important;
}

.mat-form-field-no-padding .mat-form-field-wrapper {
  margin-bottom: -1.25em;
  margin-top: -1.25em;
}

.mat-form-field-no-padding .mat-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.export-all,
.dashboard-link {
  height: 26px;
  width: 216px;
  color: #39F;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  font-size: calc(10px + 0.25em);
  cursor: pointer;
  line-height: 22px;
}

.search-card {
  height: 105px;
  width: 15vw;
  border-radius: 10px;
  background-color: #F2F2F2 !important;
  padding: 0px;
}

.date-label {
  color: #607d8b;
  font-weight: 575;
  font-size: 0.8vw;
}

td,
th {
  text-align: left;
}

.icon--click-hover {
  cursor: pointer;
}

.icon-feedback {
  font-size: 30px;
}

.centre-page {
  position: fixed;
  top: 95vh;
  left: 49vw;
}

@media only screen and (min-width: 720px) and (max-width: 1150px) {
  .topnav-spacer {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 720px) {
  .topnav-spacer {
    margin-top: 80px;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
  line-height: 21px;
  padding-bottom: 1em;
  padding-top: 1em;
}

.graph-table-icon {
  margin-right: 10px;
}

.graph-table-icon-active {
  text-decoration: underline;
}

.mat-header-cell {
  background-color: #EAE6E0;
  padding-left: 5px !important;
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
}

.mat-cell {
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  padding: 10px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  white-space: normal !important;
}

.ng-value-container {
  white-space: normal !important;
  flex-wrap: wrap;
  min-width: 0;
}

.bottom-right {
  position: fixed;
  top: 96vh;
  left: 50px;
}

.version {
  margin-left: 10px;
  font-size: 30px;
  color: rgb(39, 39, 39, 0.03)
}

.widget {
  border: 1px solid gray;
  padding: 5px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 10px;
  background-color: lightblue;
  color: black;
}

.widget-container {
  margin-left: 50px calc(3vw - 25px);
  margin-right: 50px calc(3vw + 25px);
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.active-widget {
  height: fit-content;
  margin: 12px;
}